<template>
    <BaseLayout>
        	<section class="ol-news">
                <div class="ol-wrapper ol-wrapper--large">
                    <div class="ol-breadcrumb">
                        <a href="/" class="ol-breadcrumb__link">Home</a>  >
                        <a href="#" class="ol-breadcrumb__link">Artikel</a>
                    </div>
                    <Slideshow/>
                    <h1 style="display:none">Inspirasi Hunian Terkini | MORE Furniture</h1>
                    <div class="break-24"></div>
                    <CategoryArticle />
                    <div class="break-24"></div>
                    <div class="ol-news__wrapper add-fix">
                        <div class="ol-news__list ol-news__list-home">
                            <div class="ol-news__list-wrapper">
                                <h2 class="ol-section-title">
                                    <span>Editor Pick</span>
                                    <i></i>
                                </h2> 
                                <div>
                                <div  v-for="item in articleEditor" :key="item.id" class="ol-home-news__list-item ol-home-news__list-item-home" >
                                    <div class="ol-home-news__list-item-images">
                                        <figure class="ol-home-news__list-item-images-figure ol-home-news__list-item-images-figure-home">
                                            <router-link :to="'/artikel/'+item.slug">
                                                <img :srcset="item.image_url" :alt="item.title" :title="item.title">
                                            </router-link>
                                        </figure>
                                    </div>
                                    <div class="ol-home-news__list-item-desc ol-home-news__list-item-desc-home">
                                        <router-link :to="'/artikel/'+item.slug">
                                            <h3 class="ol-home-news__list-item-desc-title-home">
                                                {{item.title}}
                                            </h3>
                                        </router-link>
                                    </div>

                                </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="break-24"></div>
                    <div class="ol-news__wrapper add-fix">
                        <!-- ol-news__list -->
                        <div class="ol-news__list ol-news__list-home">
                            <div class="ol-news__list-wrapper">
                                
                                <h2 class="ol-section-title">
                                    <span>Inspirasi Hunian Terkini</span>
                                    <i></i>
                                </h2>
                                <!-- ol-home-news__list-item -->
                                <div v-if="!loading">
                                <div  v-for="item in List" :key="item.id" class="ol-home-news__list-item ol-home-news__list-item-home" >

                                    <!-- ol-home-news__list-item-images -->
                                    <div class="ol-home-news__list-item-images">
                                        <figure class="ol-home-news__list-item-images-figure ol-home-news__list-item-images-figure-home">
                                            <router-link :to="'/artikel/'+item.slug">
                                                <img :srcset="item.image_url" :alt="item.title" :title="item.title">
                                            </router-link>
                                        </figure>
                                        <!-- <span class="ol-home-news__list-item-images-date">{{ date('j', strtotime($berita['tanggal'])) }} <i>{{ jdmonthname(date('m', strtotime($berita['tanggal'])),0) }}</i></span> -->
                                    </div>
                                    <!-- /ol-home-news__list-item-images -->

                                    <!-- ol-home-news__list-item-desc -->
                                    <div class="ol-home-news__list-item-desc ol-home-news__list-item-desc-home">
                                        <router-link :to="'/artikel/'+item.slug">
                                            <h3 class="ol-home-news__list-item-desc-title-home">
                                                {{item.title}}
                                                <!-- <router-view :to="item.slug">
                                                    {{item.title}}
                                                </router-view> -->
                                            </h3>
                                        </router-link>
                                        <!-- <p class="ol-home-news__list-item-desc-text">{{ item.description_meta}}</p> -->
                                        <!-- <router-link :to="'/artikel/'+item.slug" class="ol-btn ol-btn--blue" > Selengkapnya &raquo; </router-link> -->
                                    </div>
                                    <!-- /ol-home-news__list-item-desc -->

                                </div>
                                </div>
                                <ArtikelLoader v-if="loading" :qty="3" />
                                <!-- /ol-home-news__list-item -->
                            </div>
                        </div>
                        
                        <!-- ol-paging -->
                        <div class="ol-paging" v-if="laravelPagenation">
                            <pagination :data="laravelPagenation" @pagination-change-page="getData" :limit="2"></pagination>
                        </div>
                        <!-- /ol-paging -->
                        <!-- /ol-news__list -->
                    </div>
                </div>
            </section>
    </BaseLayout>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import BaseLayout from '@/components/landing/BaseLayout.vue';
import Tag from '@/components/artikel/Tag.vue';
import Slideshow from '@/components/artikel/Slideshow.vue';
import ArtikelLoader from '@/components/loading/ArtikelLoader.vue';
import CategoryArticle from "@/components/home/CategoryArticle.vue";

export default {
    name: "Artikel",
    metaInfo() {
        return{
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components:{
        BaseLayout,
        Tag,
        Slideshow,
        ArtikelLoader,
        CategoryArticle
    },
     data() {
        return {
            tota:0,
            page:1,
            per_page:12,
            tags:this.$route.query.tags ? this.$route.query.tags : null,
            more:false,
            List:[],
            laravelPagenation:null,
            loading:true
        }
     },
     computed:{
        ...mapGetters("artikel", ["articleEditor"]),
    },
    methods:{
        ...mapActions("artikel", ["fetchData","getArticleEditor"]),
        
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        toDetail(slug){
             this.$router.replace({ path: '/artikel/'+slug});
            //  this.getData(this.page);

        },
        goTags(tag){
            window.document.title = tag.name;
            
            const titleEl = document.querySelector('head title');

            titleEl.textContent = tag.name;

            this.$router.push({ path: "/artikel", query: {tags: tag.name} });
            this.tags = tag.name;
            this.getData(this.page);
        },
        async getData(page = 1){
            this.loading=true;
            this.jqScrolling2top();
            let data={
                page: page,
                tags:this.tags,
                per_page:this.per_page
            };
            this.fetchData(data).then((result) =>{
                this.loading = false;
                let response = result.data;
                this.laravelPagenation = response;
                this.List = response.data;
                
            }).catch((error)=>{
                this.loading = false;
                this.$router.push("/404");
                
            });
        },
    },
    async mounted(){
        // this.jqScrolling2top();
        this.getData(this.page);
        await this.getArticleEditor();
        // $('.list-category').slick({
        //     dots: false,
        //     infinite: true,
        //     speed: 300,
        //     autoplay: true,
        //     autoplaySpeed: 2000,
        //     slidesToShow: 1,
        //     centerMode: true,
        //     variableWidth: true
        // });
    }
}
</script>
<style scoped lang="css">
@import '../../assets/css/blog.css';
</style>